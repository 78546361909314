import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Cookies } from "react-cookie";
import axios from "axios";
import './ProjectsList.scss';
import { ImExit } from "react-icons/im";

axios.defaults.withCredentials = false;

function ProjectList() {
  const history = useHistory();
  const cookie = new Cookies();

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  });
  const logout = () => {
    localStorage.removeItem('urls');
    localStorage.removeItem('token');

    cookie.remove('access-token', { path: '/' });
    history.push('/login');
  }

  const urls = localStorage.getItem('urls')?.split(',');

  return (
    <div>

      <nav className="navbar navbar-dark bg-dark navbar-expand-lg" aria-label="Global navigation - Standard example">
        <div className="container-xxl">
          <div className="navbar-brand me-auto me-lg-4">
            <img src="./logoOrange.png" width="50" height="50" loading="lazy" alt="logo-orange" />
          </div>
          <div id="global-header-1.1" className="navbar-collapse collapse me-lg-auto global-header-1">
            <ul className="navbar-nav">
              <li className="nav-item"><button className="nav-link active" href="#" aria-current="page">Mes projets</button></li>
            </ul>
          </div>

          <div id="global-header-1.2" className="navbar-collapse collapse d-sm-flex global-header-1">
            <ul className="navbar-nav flex-row">
              <li className="nav-item">
              </li>
              <li className="nav-item">
              </li>
              <li className="nav-item">
                <button href="" className="nav-link nav-icon">
                  <ImExit onClick={logout} />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="list-group" style={{
        width: '85%',
        margin: 'auto',
        marginTop: '2%',
        marginBottom: '10%',
      }}>
        <button className="list-group-item list-group-item-action list-group-item-dark fw-bold">Liste de mes ordinateurs virtuels</button>
        {urls?.sort().map((url, index) => {
          return <a style={{
            display: 'inline-flex',
          }} key={index} id={url} href={"https://" + url} className="list-group-item list-group-item-action" value={url}>
            <img width="30" src="./pc-icon.png" style={{
              marginRight: '9px',
              fontSize: 'x-large',
            }} alt="Computer logo" />

            <p style={{
              position: 'relative',
              top: '7px',
            }}>{url.split('.')[0]}</p>
          </a>
        }
        )}
      </div>
      <footer className="footer bg-dark navbar-dark fixed-bottom" style={{ maxHeight: '10%' }}>
        <h2 className="visually-hidden">Sitemap & information</h2>
        <div className="container-xxl footer-terms">
          <ul className="navbar-nav gap-md-3">
            <li className="fw-bold">© Orange 2023</li>
            <li><button className="nav-link" >Terms and conditions</button></li>
            <li><button className="nav-link" >Privacy</button></li>
            <li><button className="nav-link" >Accessibility statement</button></li>
            <li><button className="nav-link" >Cookie policy</button></li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default ProjectList;
