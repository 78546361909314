import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Login from './components/LoginPage';
import ProjectList from './components/ProjectsList';

function App() {
  return (
    <Router>
      <div>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/login" component={Login} />
        <Route path="/projects" component={ProjectList} />
      </div>
    </Router>
  );
}

export default App;
