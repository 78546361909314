import React, { useState, useEffect } from "react";
import axios from "axios"
import './LoginPage.scss'
import { useHistory } from 'react-router-dom';
import {Cookies} from 'react-cookie';

axios.defaults.withCredentials = true;

function Login() {
    const queryParameters = new URLSearchParams(window.location.search)
    const redirect_uri =  queryParameters.get("redirect_uri");
    const cookies = new Cookies();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem('token') && !redirect_uri) {
            history.push('/projects');
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        try {
                const response = await axios.post('https://auth.plateforme.cybertraininglabs.net/user/login', {
            // const response = await axios.post('http://127.0.0.1:80/user/login', {
                username: username,
                password: password
            });

            if (response.data.success) {
                cookies.set('access-token', response.data.token, { path: '/', domain: '.plateforme.cybertraininglabs.net', maxAge: 86400 });
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('urls', response.data.projects);

                if (redirect_uri) {
                    window.open('https://' + redirect_uri, '_self');
                }
                else
                    history.push('/projects');
            } else {
                document.getElementById('username').classList.add('is-invalid');
                document.getElementById('password').classList.add('is-invalid');
                setError(response.data.message ? response.data.message : 'Login and/or Password incorrect.');
            }
        } catch (err) {
            document.getElementById('username').classList.add('is-invalid');
            document.getElementById('password').classList.add('is-invalid');
            setError(err?.response?.data?.message && err.response.status === 401 ? "Login and/or Password incorrect" : 'Une erreur est survenue');
        }
    };

    return (
        <div>
            <nav className="navbar navbar-dark bg-dark">
                <div className="container-fluid">
                <div className="navbar-brand">
                    <span className="stretched-link">
                    <img src="./logoOrange.png" width="50" height="50" alt="Boosted - Back to Home" loading="lazy"/>
                    </span>
                    <h1 className="title">CyberTrainingLabs</h1>
                </div>
                </div>
            </nav>
            <div className="d-flex align-items-center justify-content-center" style={{
                border: '0.125rem solid #ccc',
                width: '25%',
                margin: 'auto',
                marginTop: '35px'
            }}>
        <form className="px-4 py-3" style={{ width: '100%' }} onSubmit={handleSubmit}>
            <div className="mb-3">
            <label for="username" className="form-label is-required">Username</label>
            <input type="text"  className="form-control"  id="username" value={username} onChange={e => {
                setUsername(e.target.value);
                document.getElementById('username').classList.remove('is-invalid');
                error && setError(null);
            }} required placeholder="username"/>
            </div>
            <div className="mb-3">
            <label for="password" className="form-label is-required">Password</label>
            <input type="password" className="form-control" value={password} onChange={e => {
                setPassword(e.target.value);
                document.getElementById('password').classList.remove('is-invalid');
                error && setError(null);
            }} required id="password" placeholder="Password"/>
            </div>
            {error && <div class="alert alert-danger" role="alert">
                <span class="alert-icon"><span class="visually-hidden">Error</span></span>
                <p style={{ color: 'red'}}>{error}</p>
            </div>}
            <button type="submit" className="btn btn-primary mt-2">Sign in</button>
        </form>
        </div>
            <footer className="footer bg-dark navbar-dark fixed-bottom">
            <h2 className="visually-hidden">Sitemap & information</h2>
            <div className="container-xxl footer-terms">
                <ul className="navbar-nav gap-md-3">
                <li className="fw-bold">© Orange 2023</li>
                <li><button className="nav-link" >Terms and conditions</button></li>
                <li><button className="nav-link" >Privacy</button></li>
                <li><button className="nav-link" >Accessibility statement</button></li>
                <li><button className="nav-link" >Cookie policy</button></li>
                </ul>
            </div>
            </footer>
        </div>
        
        // <form onSubmit={handleSubmit}>

        //     <div className="login-block">
        //         <h1>Login</h1>
        //         <input type="text" value={username} onChange={e => setUsername(e.target.value)} required placeholder="Username" id="username" />
        //         <input type="password" value={password} onChange={e => setPassword(e.target.value)} required placeholder="Password" id="password" />
        //         {error && <p style={{ color: 'red' }}>{error}</p>}
        //         <button type="submit">Submit</button>
        //     </div>
        // </form>
    );
}

export default Login;
